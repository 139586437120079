'use client'
import { useEffect } from 'react'
import { intercomAppId } from 'src/utils/intercom'
import { useUserInfoProvider } from '../hooks/providers/UserInfoProvider'

export default function IntercomClientComponent() {
    const { userInfo } = useUserInfoProvider()
    useEffect(() => {
        window.intercomSettings = {
            api_base: 'https://api-iam.intercom.io',
            app_id: intercomAppId,
            user_id: userInfo?.id,
            user_hash: userInfo?.intercomHash,
        }
        if (window.Intercom) {
            window.Intercom('reattach_activator')
            window.Intercom('update', intercomSettings)
        } else {
            const intercomScript = document.createElement('script')
            intercomScript.type = 'text/javascript'
            intercomScript.async = true
            intercomScript.src = `https://widget.intercom.io/widget/${intercomAppId}`
            intercomScript.onload = () => window.Intercom('update', intercomSettings)
            document.body.appendChild(intercomScript)
        }
    }, [userInfo])
    return null
}
