'use client'
import React from 'react'
import { getApiUrlPrefix, getSessionToken } from 'src/utils/api'

export interface IUserInfo {
    id: string
    firstName: string
    lastName: string
    picture?: string
    emailAddress?: string
    signedUp?: Date
    created: Date
    modified: Date
    eula?: string
    plan: string | null
    intercomHash?: string
    admin?: boolean
}

export interface IUserInfoProvider {
    userInfo?: IUserInfo
    userInfoLoading: boolean
    forceReloadUser?: () => Promise<IUserInfo | undefined>
}

const UserInfoContext = React.createContext({})

const UserInfoProvider = ({ children }: { children: React.ReactNode }) => {
    const [userInfoProvider, setUserInfoProvider] = React.useState<IUserInfoProvider>({
        userInfoLoading: true,
    })
    React.useEffect(() => {
        const loadUser = async (): Promise<IUserInfo | undefined> => {
            setUserInfoProvider(exisingUserInfoProvider => ({
                ...exisingUserInfoProvider,
                userInfo: undefined,
                userInfoLoading: true,
                forceReloadUser: loadUser,
            }))
            if (getSessionToken() === undefined) {
                return undefined
            }
            return fetch(`${getApiUrlPrefix()}/v1/user`, {
                method: 'GET',
                credentials: 'include',
            })
                .then(async response => {
                    if (response.ok) {
                        const userInfo = (await response.json()).user
                        setUserInfoProvider(exisingUserInfoProvider => ({
                            ...exisingUserInfoProvider,
                            userInfo,
                            userInfoLoading: false,
                        }))
                        return userInfo
                    } else {
                        setUserInfoProvider(existingUserInfoProivder => ({
                            ...existingUserInfoProivder,
                            userInfo: undefined,
                            userInfoLoading: false,
                        }))
                        return undefined
                    }
                })
                .catch(err => {
                    setUserInfoProvider(existingUserInfoProivder => ({
                        ...existingUserInfoProivder,
                        userInfo: undefined,
                        userInfoLoading: false,
                    }))
                    return undefined
                })
        }
        loadUser()
    }, [])
    return <UserInfoContext.Provider value={userInfoProvider}>{children}</UserInfoContext.Provider>
}

export const useUserInfoProvider = (): IUserInfoProvider => {
    return React.useContext(UserInfoContext) as IUserInfoProvider
}

export default UserInfoProvider
